/* Select.scss */

.normal .MuiOutlinedInput-root {
	background-color: #ffffff !important; /* Ensure background is white */
	border-radius: 0.5rem !important; /* More rounded borders */
	box-shadow: none !important; /* Remove shadow */
}

.normal .MuiOutlinedInput-input {
	color: #4E5155 !important;
	padding: 0.75rem 1.25rem !important;
	font-size: 1rem !important;
	line-height: 1.5 !important;
	border-radius: 0.5rem !important; /* More rounded borders */
}

.normal .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
	border-width: 1px !important; /* Line only 1px */
	border-color: #3ba4c1 !important; /* Highlight color */
}

.normal .MuiSelect-root.Mui-focused .MuiOutlinedInput-notchedOutline {
	border-width: 1px !important;
	border-color: #3ba4c1 !important;
}
