@import "./_appwork/include";
@import "./_theme/common";
@import "./_theme/libs";
@import "./_theme/uikit";

$primary-color: $blue;
$body-bg: #f5f5f5;

body {
  background: $body-bg;
}

.bg-body {
  background: $body-bg !important;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $font-family-header-sans-serif;
  font-weight: 600;
}

@include appwork-common-theme($primary-color);
@include appwork-libs-theme($primary-color);
@include appwork-uikit-theme($primary-color);

// Navbar

@include appwork-navbar-variant('.bg-navbar-theme', #fff, $color: $text-muted, $active-color: $body-color);

.layout-navbar {
  box-shadow: 0 1px 0 $border-color;
}

// Sidenav

@include appwork-sidenav-variant('.bg-sidenav-theme', #31495b, $color: $body-bg);

// Footer

@include appwork-footer-variant('.bg-footer-theme', #fff, $color: $text-muted, $active-color: $body-color);